<template>
  <div>
    <v-dialog v-model="model" max-width="80%" v-if="editThingy.questionnaireRoot">
      <v-sheet class="overflow-y-auto ma-4">
        <ClarificationWizard
          v-if="questionnaireRoot && questions"
          :questionnaire="questionnaireRoot"
          :questions="questions"
          @close="close"
        />
      </v-sheet>
    </v-dialog>
    <LoadingSpinner
      loadingMessage="Henter behovsafdækning"
      :visible="isLoading && !questionnaireRoot"
    />
  </div>
</template>

<script setup lang="ts">
import { Tender, useStore, useEditController, DeepQuestionnaireRoot } from '@dims/components';
import { ref, computed } from 'vue';
import ClarificationWizard from '@/components/Tender/Clarification/ClarificationWizard.vue';

const { tender } = defineProps<{ tender: Tender }>();
const model = defineModel<boolean>();
const store = useStore();
const isLoading = ref(false);

const editThingy = useEditController(tender.id, store.backend, specificationSaved);

const questionnaireRoot = computed((): DeepQuestionnaireRoot | null => editThingy.questionnaireRoot.value);
const questions = computed(() => questionnaireRoot.value?.questionnaire.questions);

// Callback from EditController
function specificationSaved() {
  // Nothing
}

function close() {
  model.value = false;
}

</script>
