<!--
  DO NOT EDIT THIS FILE

  File is duplicated across sites, so should be kept in sync
-->
<template>
  <div>
    <v-form ref="form" :disabled="deadlineExpired">
      <v-container fluid>
        <div class="align-self-center mb-5" v-if="isDraft && deadlineExpired">
          <p class="text-black ma-0">
            Du kan ikke indsende tilbud, da tilbudsfristen er overskredet
          </p>
        </div>
        <div v-if="offerPublishedDate !== ''" class="mb-10">
          <p>
            <strong>Tilbud indsendt {{ offerPublishedDate }}</strong>
          </p>
          <div>
            <p v-if="!deadlineExpired">
              Du kan frem til tilbudsfristen trække dit tilbud tilbage og evt.
              tilrette og indsende et nyt. Det gør du under fanen 'Overblik'.
              Når tilbudsfristen er udløbet, kan du ikke længere ændre eller
              tilbagekalde dit tilbud
            </p>
          </div>
          <v-btn
            class="primary-button"
            @click="offerDetailsDialog = !offerDetailsDialog"
          >
            Se dit tilbud
          </v-btn>
          <v-dialog v-model="offerDetailsDialog" width="1400">
            <v-card v-if="offerDetailsDialog" class="bg-canvas px-5 pt-10">
              <v-card-text>
                <OfferSpecificationViewer :tender="tender" :offer="offer" />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  @click="closeOfferDetailsDialog()"
                  class="mb-4 primary-button"
                >
                  Luk
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-row>
          <v-col>
            <v-card class="pa-3 bg-canvas">
              <v-card-text>
                <div class="d-flex">
                  <div>
                    <h1 class="mt-0 mb-2 font-weight-bold">
                      Udfyld dit tilbud
                    </h1>
                    <div class="mt-2 mb-7">
                      <p>
                        Kravbesvarelse og prisafgivelse herunder udgør dit
                        tilbud
                      </p>
                      <!-- diff start -->
                      <p>
                        Udfyld dit tilbud ved (1) at besvare evalueringskravene,
                        (2) afgive priser og (3) bekræfte, at dit tilbud
                        opfylder samtlige af kundens krav.
                      </p>
                      <!-- diff end -->
                      <p v-if="isDraft && error" class="text-error">
                        Kan ikke gemme kladde!
                      </p>
                    </div>
                  </div>
                </div>
                <v-card>
                  <v-card-text class="pa-10">
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus
                          :label="1"
                          :complete="isSectionComplete(specificationSections)"
                        />
                      </v-col>
                      <v-col>
                        <!-- diff start -->
                        <h2 class="mt-0 mb-2">Kravbesvarelse</h2>
                        <p>
                          Besvarelsen er opdelt i 'Sortimentsbilag', som
                          omhandler krav til selve ydelsen samt 'Øvrige bilag',
                          der vedrører øvrige krav til samarbejdet.
                        </p>
                        <p>
                          Kravene er enten <b>kontraktkrav</b> eller <b>evalueringskrav</b>.
                        </p>
                        <p>
                          <b>Kontraktkrav</b> er krav, dit tilbud skal leve op til. De
                          kræver ikke besvarelse fra din side, men er afgørende
                          for, om dit tilbud opfylder alle kundens krav
                          (nedenstående trin 3).
                        </p>
                        <p>
                          <b>Evalueringskrav</b> indgår i kundens evaluering af dit
                          tilbud, og skal besvares. Det gør du ved at downloade
                          og besvare det enkelte 'Evalueringsdokument', og
                          uploade din besvarelse.
                        </p>
                        <!-- diff end -->
                        <v-dialog v-model="offerPreviewDialog" max-width="95%">
                          <template #activator="{ props }">
                            <ArrowButton
                              :disabled="!offerQuestionnaire || !isDraft"
                              v-bind="props"
                              class="mt-2"
                              :class="{ secondary: isSectionComplete(specificationSections) }"
                            >
                              <!-- diff start -->
                              Besvar evalueringskrav
                              <!-- diff end -->
                            </ArrowButton>
                          </template>
                          <v-card>
                            <OfferDetailsForm
                              v-if="offerQuestionnaire"
                              :tender="tender"
                              :offerQuestionnaire="offerQuestionnaire"
                              :tabType="'SupplierSpecification'"
                              @closeDialog="closeOfferPreviewDialog"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus
                          :label="2"
                          :complete="isSectionComplete([priceSection])"
                        />
                      </v-col>
                      <v-col>
                        <!-- diff start -->
                        <h2 class="mt-0 mb-2">Prisafgivelse</h2>
                        <p>
                          Prisafgivelsen er opdelt i en række priselementer, der
                          afspejler kundens indkøb fx 'Driftsomkostninger'.
                        </p>
                        <p>
                          Hvert priselement har én eller flere pristabeller med
                          prispunkter, du skal udfylde. For hver pristabel
                          findes en vejledning til udfyldelse. Vejledningen
                          beskriver også hvordan den evalueringstekniske pris
                          beregnes. I takt med din udfyldelse, kan du se prisen
                          aggregeret op pr. pristabel og priselement.
                        </p>
                        <p>
                          I 'Opsummering' (til højre), kan du følge de priser,
                          du har afgivet, samt den evalueringstekniske pris
                        </p>
                        <p>
                          <b>Bemærk</b> priser skal
                          afgives ekskl. moms og inkl. kundebetaling til SKI.
                          <DimsTooltip>
                            <p>
                              Leverandøren skal på vegne af Kunden videreformidle
                              kundebetalingen på 2,0 % af Kundens købssum til SKI.
                              Leverandøren skal derfor indregne kundens betaling til
                              SKI på 2,0 % i sine tilbudspriser i det konkrete
                              indkøb.
                            </p>
                            <p>Kundebetalingen beregnes efter følgende formel:</p>
                            <p class="text-decoration-underline">
                              Kundens købssum eksklusive moms i DKK x 2,0 % =
                              Kundebetalingen i DKK
                            </p>
                            <p>
                              Leverandørens rapportering samt formidling af kundens
                              betaling til SKI er beskrevet nærmere i Bilag 1
                              Trepartsaftale.
                            </p>
                          </DimsTooltip>
                        </p>
                        <!-- diff end -->
                        <v-dialog v-model="offerFormDialog" max-width="95%">
                          <template #activator="{ props }">
                            <ArrowButton
                              :disabled="!offerQuestionnaire || !isDraft"
                              v-bind="props"
                              class="mt-2"
                              :class="{ secondary: isSectionComplete([priceSection]) }"
                            >
                              <!-- diff start -->
                              Afgiv priser
                              <!-- diff end -->
                            </ArrowButton>
                          </template>
                          <v-card>
                            <OfferDetailsForm
                              v-if="offerQuestionnaire"
                              :tender="tender"
                              :offerQuestionnaire="offerQuestionnaire"
                              :tabType="'SupplierOffer'"
                              @closeDialog="closeOfferFormDialog"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus
                          :label="3"
                          :complete="specificationAccepted === true || specificationAccepted === false"
                        />
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Kundens kravspecifikation</h2>
                        <div>
                          Bekræft om dit tilbud opfylder samtlige krav i kundens
                          kravspecifikation.
                        </div>
                        <v-radio-group
                          v-model="specificationAccepted"
                          hide-details
                          :disabled="!isDraft"
                          density="compact"
                        >
                          <v-radio :value="true">
                            <template #label>
                              <small>Ja, alle krav er opfyldt</small>
                            </template>
                          </v-radio>
                          <v-radio :value="false">
                            <template #label>
                              <small>
                                Nej, alle krav er
                                <span class="font-weight-bold">ikke</span>
                                opfyldt
                              </small>
                            </template>
                          </v-radio>
                        </v-radio-group>
                        <div
                          v-if="specificationAccepted === false"
                          class="text-red"
                        >
                          <strong>Bemærk:</strong> du har angivet, at dit tilbud
                          ikke opfylder kravene i kravspecifikationen, dit
                          tilbud vil derfor som udgangspunkt blive vurderet som
                          ukonditionsmæssigt.
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <!-- diff start -->
                      <v-col cols="auto">
                        <StepStatus :label="4" :complete="espdFile !== undefined" />
                      </v-col>
                      <v-col>
                        <OfferDetailsESPD
                          :tender="tender"
                          :offer="offer"
                          :disabled="!validOffer || !isDraft"
                          :espdFile="espdFile"
                        />
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus :label="5" :complete="offerLetter !== undefined" />
                      </v-col>
                      <v-col>
                        <OfferDetailsOfferLetter
                          :tender="tender"
                          :offer="offer"
                          :disabled="!isDraft"
                          :offerLetter="offerLetter"
                        />
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <!-- diff end -->
                    <v-row>
                      <v-col cols="auto">
                        <!-- diff start -->
                        <StepStatus :label="6" :complete="!isDraft" />
                        <!-- diff end -->
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                        <p v-if="!validOffer && isDraft">
                          <!-- diff start -->
                          Færdiggør trin 1, 2 og 3, for at sende dit tilbud
                        <!-- diff end -->
                        </p>
                        <v-dialog
                          v-model="confirmationOfferDialog"
                          persistent
                          scrollable
                          max-width="80%"
                        >
                          <template #activator="{ props }">
                            <ArrowButton
                              data-cy="send-offer-button"
                              :disabled="!validOffer || !isDraft"
                              v-bind="props"
                              class="mt-2"
                            >Se og send tilbud</ArrowButton>
                          </template>
                          <v-card>
                            <SendOfferConfirmationDialog
                              @closeConfirmationDialog="closeOfferConfirmationDialog"
                              @makeOfferActive="makeOfferActive"
                              :offer="offer"
                              :tender="tender"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <TcoCalculationSummary
              :questions="tcoCalculationQuestions"
              :tcoCalculationResult="tcoCalculationResult"
              cardStyle="#fff9ef"
              tableStyle="transparent"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <LoadingSpinner :loadingMessage="loadingMessage" :visible="isLoading || isSaving" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  LoadingSpinner,
  Offer,
  Tender,
  UpdateOfferRequest,
  useStore,
  SendOfferConfirmationDialog,
  useBackend,
  dateUtils,
  DeepQuestionnaire,
  ArrowButton,
  tenderHelper,
  StepStatus,
  OfferDetailsOfferLetter,
  OfferDetailsForm,
  OfferDetailsESPD,
  useDebouncedOfferQuestionnaire,
} from '@dims/components';
import OfferSpecificationViewer from '../Specification/OfferSpecificationViewer.vue';
import TcoCalculationSummary from './TcoCalculation/TcoCalculationSummary.vue';

const { tender, offer } = defineProps<{ tender: Tender, offer: Offer }>();
const store = useStore();
const backend = useBackend();
const confirmationOfferDialog = ref(false);
const offerDetailsDialog = ref(false);
const offerFormDialog = ref(false);
const offerPreviewDialog = ref(false);
const loadingMessage = ref('Vent venligst');
const isLoading = ref(false);
const isSaving = ref(false);
const error = ref(false);
const priceSection = ref(2);
const specificationSections = ref([0, 1]);
const offerQuestionnaire = useDebouncedOfferQuestionnaire(tender.id, offer.id, store.backend, afterQuestionnaireUpdated);

// Callback when questionnaire has changed
function afterQuestionnaireUpdated(_questionnaire: DeepQuestionnaire) {
  // not additional upates
}

// Questions used for offer status overview. Exclude "Generelle informationer"
const tcoCalculationQuestions = computed(() => offerQuestionnaire.value?.questionnaire.questions[priceSection.value]?.questions?.filter((q) => q.questionId !== '443e0e09-8527-4a98-a4a2-4e0771427ad8') ?? []);

const deadlineExpired = computed(() => tenderHelper.isDeadlineExpired(tender));

function closeOfferConfirmationDialog() {
  confirmationOfferDialog.value = false;
}

function closeOfferDetailsDialog() {
  offerDetailsDialog.value = false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}

function closeOfferPreviewDialog() {
  offerPreviewDialog.value = false;
}

async function makeOfferActive(signerName: string) {
  closeOfferConfirmationDialog();
  if (offer.state === 'Draft' && signerName) {
    const update: UpdateOfferRequest = {
      data: {},
      signerName,
    };
    loadingMessage.value = 'Sender tilbud';
    isLoading.value = true;
    try {
      await store.updateOfferAction(offer, update);
      await store.publishOfferAction(offer);
      await backend.markerService.createMarker(offer);
    } catch (e) {
      store.setSnackbarText('Kan ikke sende tilbud');
    } finally {
      isLoading.value = false;
    }
    loadingMessage.value = 'Vent venligst';
  }
}

async function acceptSpecification() {
  console.info('acceptSpecification');
  offer.specificationAccepted = true;
  await saveDraftNow();
}

async function rejectSpecification() {
  console.info('rejectSpecification');
  offer.specificationAccepted = false;
  await saveDraftNow();
}

const specificationAccepted = computed({
  get(): boolean | undefined {
    return offer.specificationAccepted ?? undefined;
  },
  set(value: boolean | undefined) {
    if (value) {
      void acceptSpecification();
    } else {
      void rejectSpecification();
    }
  },
});

async function saveDraftNow() {
  console.info('saving offer');
  isSaving.value = true;
  error.value = false;
  try {
    const update: UpdateOfferRequest = {
      specificationAccepted: offer.specificationAccepted,
    };
    await store.updateOfferAction(offer, update);
    console.info('offer saved');
  } catch (err) {
    error.value = true;
    console.error('Could not save offer draft', err);
  } finally {
    isSaving.value = false;
  }
}

const validOffer = computed(() => (
  !tenderHelper.isDeadlineExpired(tender)
      && (specificationAccepted.value === true
        || specificationAccepted.value === false)
      && offerQuestionnaire.value?.complete
));

const isDraft = computed((): boolean => offer.state === 'Draft');

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

const tcoCalculationResult = computed(() => offerQuestionnaire.value?.questionnaire.tcoCalculationResult);

function isSectionComplete(sections: number[]) {
  const topLevelQuestions = offerQuestionnaire.value?.questionnaire.questions;
  return topLevelQuestions
    ? sections.every((section) => topLevelQuestions[section]?.questions?.every((q) => q.complete))
    : false;
}

const espdFile = computed(() => offer.artifacts?.find((x) => x.templateArtifactKey === 'espd' && x.templateKey === 'offer'));
const offerLetter = computed(() => offer.artifacts?.find((x) => x.templateArtifactKey === 'offerletter' && x.templateKey === 'offer'));

</script>
